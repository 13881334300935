<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';

export default {
	name: "BoStaticAbout",
	extends: Base,
  data(){
    return {
			Name:"BoStaticAbout",
			mrTags: [],
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
		this.refreshData()
	},
  watch:{
    '$route.query'(v){
			this.refreshData()
		}
  }
};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="panel panel-flat" v-if="!$route.params.id">
			<div class="panel-body panel-filter">
				<VForm @resp="search">
					<div class="row">
						<div class="col-sm-3">
							<h3 class="box-title">{{ObjectName}} List <span v-if="data.total">({{(data.total||0).format()}})</span></h3>
						</div>
						<div class="col-sm-5">
						</div>
						<div class="col-sm-3">
							<div class="has-feedback has-feedback-left">
								<input type="search" v-model="filter.search" class="form-control" placeholder="Type and hit Enter">
								<div class="form-control-feedback">
									<i class="icon-search4 text-size-base text-muted"></i>
								</div>
							</div>
						</div>
						<div class="col-sm-1">
							<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
						</div>
					</div>
				</VForm>
			</div>
			<div class="table-responsive ajax-table">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>#</th>
							<th><SortField name="msc_page"></SortField></th>
							<th>{{fields.msc_title}}</th>
							<th width="120px">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(v,k) in data.data" :key="k">
							<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
							<td>{{v.msc_page}}</td>
							<td>{{v.msc_title}}</td>
							<td class="btn-action">
								<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="icon-pencil"></i></router-link>
							</td>
						</tr>
						<tr v-if="NotFound">
							<td colspan="99">
								<h3 class="tc">{{NotFound}}</h3>
							</td>
						</tr>
						<tr v-if="data.data===false">
							<td colspan="99">
								<LoadingSpinner light></LoadingSpinner>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="panel-body">
				<div class="pull-right">
					<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="panel panel-flat" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="panel-heading">
					<h2 class="panel-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h2>
				</div>
				<div class="panel-body">
					<div class="info"></div>
					<div class="form-horizontal">
						<BoField col="5" name="msc_page" v-model="row.msc_page" :attr="{readonly:true}"></BoField>
						<BoField col="5" name="msc_image" info="Rasio Foto 1:1, dengan ukuran max. 450x450px">
                            <Uploader name="msc_image" type="static_content" uploadType="cropping" v-model="row.msc_image" v-bind="validation('msc_image')"></Uploader>
                        </BoField>
						<BoField col="5" name="msc_title" v-model="row.msc_title"></BoField>
						<BoField col="10" name="msc_desc">
							<CKEditor name="msc_desc" v-model="row.msc_desc" v-bind="validation('msc_desc')">
                            </CKEditor>
            			</BoField>
						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
